import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import { TestQuestionResponsesList } from "~/tests/TestQuestionResponsesList"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import { shareCandidateTestResponsePath } from "~/common/paths"
import invariant from "tiny-invariant"
import { SHARE_CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT } from "~/tests/candidateTestQueries"

export const ShareCandidateTestScreen = () => {
  const { shareTokenId } = useParams()
  invariant(shareTokenId)

  const { loading, data, error } = useQuery(
    SHARE_CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT,
    {
      variables: { shareTokenId: shareTokenId },
    }
  )

  if (loading) {
    return <LoadingIndicatorCentered />
  }

  if (error || !data?.candidateTest) {
    console.error("ERROR", error)
    return <Error message="Error loading candidate test token" />
  }

  return (
    <CandidateTestLayout
      test={data.candidateTest.test}
      candidateTest={data.candidateTest}
      backLabel="Responses"
      backPath={""}
      navigation={<></>}
    >
      {data && data.candidateTestQuestionResponses.nodes.length > 0 ? (
        <TestQuestionResponsesList
          data={data.candidateTestQuestionResponses.nodes}
          viewPath={(response) =>
            shareCandidateTestResponsePath({
              shareTokenId,
              questionResponseId: response.id,
            })
          }
          canEdit={false}
          onlyAnswered
        />
      ) : (
        <EmptyData>No responses yet.</EmptyData>
      )}
    </CandidateTestLayout>
  )
}
