import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import {
  shareCandidateTestPath,
  shareCandidateTestResponsePath,
} from "~/common/paths"
import { QuestionResponseDetails } from "~/questions/QuestionResponseDetails"
import { QuestionNavigator } from "~/questions/QuestionNavigator"
import { SHARE_QUESTION_RESPONSE_QUERY_DOCUMENT } from "~/tests/candidateTestQueries"

export const ShareCandidateTestResponseScreen = () => {
  const { shareTokenId, questionResponseId } = useParams()

  invariant(questionResponseId)
  invariant(shareTokenId)

  const { loading, data, error } = useQuery(
    SHARE_QUESTION_RESPONSE_QUERY_DOCUMENT,
    {
      variables: { questionResponseId, shareTokenId },
    }
  )

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data)
    return <Error message="Unable to load question details." />

  return (
    <CandidateTestLayout
      test={data.questionResponse.candidateTest.test}
      candidateTest={data.questionResponse.candidateTest}
      questionResponse={data.questionResponse}
      backLabel="Responses"
      backPath={shareCandidateTestPath({
        shareTokenId,
      })}
      rightModule={
        <QuestionNavigator
          questionResponse={data.questionResponse}
          viewPath={(questionResponseId) =>
            shareCandidateTestResponsePath({
              shareTokenId,
              questionResponseId,
            })
          }
        />
      }
      navigation={<></>}
    >
      <QuestionResponseDetails
        questionResponse={data.questionResponse}
        canEdit={false}
      />
      <QuestionNavigator
        questionResponse={data.questionResponse}
        viewPath={(questionResponseId) =>
          shareCandidateTestResponsePath({
            shareTokenId,
            questionResponseId,
          })
        }
      />
    </CandidateTestLayout>
  )
}
