import { CheckCircle2, ChevronLeft, XCircle } from "lucide-react"
import { Link, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import {
  candidateTestContinuePath,
  candidateTestPath,
  candidateTestsPath,
  createAccountPath,
  promotionalTestsPath,
} from "~/common/paths"
import { Divider } from "~/ui/Divider"
import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { OrganizationLogo } from "~/organizations/OrganizationLogo"
import EnvelopeOpen from "~/images/icons/envolope-open.svg?react"
import DevicePhoneMobile from "~/images/icons/device-phone-mobile.svg?react"
import Headphones from "~/images/icons/headphones.svg?react"
import VideoCamera from "~/images/icons/video-camera.svg?react"
import { LinkButton } from "~/shadcn/ui/button"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { Markdown } from "~/ui/Markdown"
import { humanizeSeconds } from "~/common/dates"

export const CandidateTestDetailsScreen = () => {
  const { currentUser } = useCurrentUserMaybe()
  const { testId } = useParams()
  invariant(testId)

  const { data, loading, error } = useQuery(TEST_QUERY_DOCUMENT, {
    variables: { testId },
  })

  if (loading)
    return (
      <div className="mt-10">
        <LoadingIndicatorCentered />
      </div>
    )
  if (error || !data)
    return (
      <div className="mt-10">
        <Error message="Unable to load test details.  This test may no longer be public." />
      </div>
    )

  const test = data.test

  return (
    <div className="container mx-auto pb-20">
      {currentUser && (
        <>
          <div className="py-3 text-gray-999 flex justify-start">
            <Link to={candidateTestsPath.pattern} className="flex items-center">
              <ChevronLeft className="mr-2 mt-0.5" size={16} />
              Tests
            </Link>
          </div>
          <Divider />
        </>
      )}

      <div className="mt-16 mb-10 flex items-center">
        <OrganizationLogo organization={test.organization} />
        <div className="">
          <div className="text-2xl text-gray-333 font-semibold">
            {test.name}
          </div>
          <div className="">
            {test.organization && (
              <div className="text-sm text-gray-999">
                {test.organization.name}
              </div>
            )}
          </div>
        </div>
        {test.expectedTimeSeconds && test.expectedTimeSeconds > 0 ? (
          <div className="ml-auto">
            <div className="text-sm text-gray-999 text-right">
              Expected Time
            </div>
            <div className="text-right">
              {humanizeSeconds(test.expectedTimeSeconds)}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="flex items-start">
        <div className="w-2/3">
          <div className="border border-gray-E6E6E3 rounded mb-6 p-8">
            <div className="text-sm text-gray-999 mb-5">Test Description</div>
            <div>
              <Markdown>{test.description}</Markdown>
            </div>
            <div className="mt-5">
              <span className="text-sm text-gray-999">Question Count:</span>
              &nbsp;&nbsp;
              {test.questionGroupsCount}
            </div>
          </div>
        </div>

        <div className="ml-8 flex-1">
          <div className="p-6 border border-gray-E6E6E3 rounded">
            <div className="flex items-center text-gray-999 text-sm mb-8">
              <div>Test Settings</div>
              <div className="ml-auto">Required</div>
            </div>

            <div className="flex flex-col gap-3">
              <TestSetting
                setting="Audio"
                required={test.requireAudio}
                Icon={Headphones}
              />
              <TestSetting
                setting="Video"
                required={test.requireVideo}
                Icon={VideoCamera}
              />
              <TestSetting
                setting="Email Verification"
                required={test.requireEmailVerification}
                Icon={EnvelopeOpen}
              />
              <TestSetting
                setting="Phone Verification"
                required={test.requirePhoneVerification}
                Icon={DevicePhoneMobile}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2 mt-8">
            <LinkButton
              to={
                currentUser
                  ? candidateTestContinuePath({ testId })
                  : `${
                      createAccountPath.pattern
                    }?redirectTo=${encodeURIComponent(
                      candidateTestPath({ testId })
                    )}`
              }
            >
              Start Test
            </LinkButton>
            <LinkButton
              to={
                currentUser
                  ? candidateTestsPath.pattern
                  : promotionalTestsPath.pattern
              }
              variant="outline-light"
            >
              Back to Tests
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const TestSetting = ({
  setting,
  required,
  Icon,
}: {
  setting: string
  required: boolean
  Icon: React.FC
}) => (
  <div className="flex items-center">
    <div className="w-6 flex justify-center">
      <Icon />
    </div>
    <div className="ml-4 mr-auto">{setting}</div>
    {required ? (
      <CheckCircle2 size={18} className="text-primary" />
    ) : (
      <XCircle size={18} className="text-communist" />
    )}
  </div>
)

export const TEST_QUERY_DOCUMENT = gql(`
  query TestQuery($testId: ID!) {
    test(testId: $testId) {
      id
      name
      description
      requireAudio
      requireVideo
      requireEmailVerification
      requirePhoneVerification
      expectedTimeSeconds
      questionGroupsCount
      organization{
        id
        name
        logoThumbUrl
      }
    }
  }
`)
