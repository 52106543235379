import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { TestQuestionResponsesList } from "~/tests/TestQuestionResponsesList"
import { PageHeader } from "~/ui/PageHeader"
import { adminQuestionResponsePath } from "~/common/paths"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import { CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT } from "~/tests/candidateTestQueries"

export const AdminCandidateTestScreen = () => {
  const { candidateTestId } = useParams()
  invariant(candidateTestId)

  const { loading, data, error } = useQuery(
    CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT,
    {
      variables: {
        candidateTestId,
      },
    }
  )

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading response." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title={`Test Response for ${data.candidateTest.test.name}`}
        subhead={`${data.candidateTest.test.organization.name}`}
      />
      <CandidateTestLayout
        test={data.candidateTest.test}
        candidateTest={data.candidateTest}
      >
        <TestQuestionResponsesList
          data={data.candidateTestQuestionResponses.nodes}
          viewPath={(response) =>
            adminQuestionResponsePath({ questionResponseId: response.id })
          }
        />
      </CandidateTestLayout>
    </div>
  )
}
