import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { organizationTestResponsePath } from "~/common/paths"
import { CandidateTests } from "~/tests/CandidateTests"
import {
  CandidateTestSortEnum,
  CandidateTestsOrganizationFilter,
} from "~/__generated__/graphql"
import { CandidateTestsTableFilter } from "~/tests/CandidateTestsTableFilter"
import { useState } from "react"

export const OrganizationTestResponsesScreen = () => {
  const { test, testId } = useOrganizationTest()
  const organizationId = useOrganizationId()

  const [filter, setFilter] = useState<CandidateTestsOrganizationFilter>({
    testId,
  })

  return (
    <OrganizationTestLayout
      test={test}
      heading="Responses"
      subhead="View responses I have received for this particular test."
    >
      <div className="flex flex-col gap-8">
        <CandidateTestsTableFilter
          organizationId={organizationId}
          filter={filter}
          onFilterChange={setFilter}
          allowTestFilter={false}
          searchParamsPrefix="responses_"
        />
        <CandidateTests
          columns={[
            "candidate",
            "test",
            "timeTakenSeconds",
            "manualScore",
            "aiScore",
            "actions",
          ]}
          defaultSort={CandidateTestSortEnum.CompletedAt}
          organizationId={organizationId}
          searchParamsPrefix="responses_"
          viewPath={(candidateTest) =>
            organizationTestResponsePath({
              organizationId,
              testId,
              candidateTestId: candidateTest.id,
            })
          }
          filter={filter}
        />
      </div>
    </OrganizationTestLayout>
  )
}
