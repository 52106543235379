import { toast } from "react-hot-toast"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__/gql"
import { useState } from "react"
import {
  shareCandidateTestPath,
  shareCandidateTestResponsePath,
  fullUrl,
} from "~/common/paths"
import { Share } from "lucide-react"
import { DropdownMenuItem } from "~/shadcn/ui/dropdown-menu"

const useShareToken = () => {
  const [createShareToken] = useSafeMutation(CREATE_SHARE_TOKEN_MUTATION)
  const createToken = async (candidateTestId: string) => {
    const result = await createShareToken({
      variables: { input: { candidateTestId } },
    })
    return result.data?.shareTokenCreate?.shareToken?.id
  }
  const copyShareLink = async (
    candidateTestId: string,
    questionResponseId?: string,
    onCopy?: (success: boolean) => void
  ) => {
    const token = await createToken(candidateTestId)
    if (token && navigator.clipboard) {
      const path = questionResponseId
        ? shareCandidateTestResponsePath({
            shareTokenId: token,
            questionResponseId,
          })
        : shareCandidateTestPath({ shareTokenId: token })

      const url = fullUrl(path)
      navigator.clipboard.writeText(url)
      onCopy && onCopy(true)
    } else {
      onCopy && onCopy(false)
    }
  }
  return { createToken, copyShareLink }
}

const CREATE_SHARE_TOKEN_MUTATION = gql(`
  mutation ShareTokenCreate($input: ShareTokenCreateInput!) {
    shareTokenCreate(input: $input) {
      shareToken {
        id
      }
    }
  }
`)

export const ShareDropdownMenuItem = ({
  candidateTestId,
}: {
  candidateTestId: string
}) => {
  const { copyShareLink } = useShareToken()

  const onCopy = async (success: boolean) => {
    if (success) {
      toast.success("Share link copied!")
    } else {
      toast.error("Failed to copy share link.")
    }
  }
  return (
    <DropdownMenuItem
      onClick={() => copyShareLink(candidateTestId, undefined, onCopy)}
    >
      Share
    </DropdownMenuItem>
  )
}

export const ShareButton = ({
  candidateTestId,
  questionResponseId,
}: {
  candidateTestId: string
  questionResponseId?: string
}) => {
  const { copyShareLink } = useShareToken()

  const [copyLabel, setCopyLabel] = useState("Share")

  const onCopy = async (success: boolean) => {
    if (success) {
      setCopyLabel("Copied!")
      setTimeout(() => {
        setCopyLabel("Share")
      }, 2500)
    } else {
      toast.error("Failed to copy share link.")
    }
  }
  return (
    <Button
      size="sm"
      variant="outline"
      onClick={() => copyShareLink(candidateTestId, questionResponseId, onCopy)}
      className="gap-2 text-gray-333"
    >
      <Share size={16} />
      {copyLabel}
    </Button>
  )
}
