import testDetailsImage from "~/images/new-test.svg"
import { Organization } from "~/__generated__/graphql"

export const OrganizationLogo = ({
  organization,
}: {
  organization: Pick<Organization, "logoThumbUrl">
}) => {
  return (
    <>
      {organization.logoThumbUrl ? (
        <img
          src={organization.logoThumbUrl}
          alt="new test"
          className="w-[120px] mr-10"
        />
      ) : (
        <img
          src={testDetailsImage}
          alt="new test"
          className="w-[128px] mr-10"
        />
      )}
    </>
  )
}
