import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "@apollo/client"
import toast from "react-hot-toast"
import { TextareaField } from "~/ui/forms/TextareaField"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "~/shadcn/ui/form"

const formSchema = z.object({
  message: z.string().min(1, {
    message: "Required",
  }),
})

export const EmailCandidateForm = ({
  candidateTestId,
  show,
  onShowChange,
}: {
  candidateTestId: string
  show: boolean
  onShowChange: (val: boolean) => void
}) => {
  const showClasses = show ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"
  const [sendEmail] = useSafeMutation(SEND_CANDIDATE_EMAIL_MUTATION)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await sendEmail({
      variables: {
        input: {
          candidateTestId,
          ...values,
        },
      },
    })

    if (!data?.sendCandidateEmail?.sent) {
      toast.error("Error sending email.")
      if (errors) {
        console.log(errors)
      }
    } else {
      toast.success("Email sent to candidate.")
      form.setValue("message", "")
      onShowChange(false)
    }
  }

  return (
    <>
      {show && (
        <div
          className={cn(
            "transition-all ease-in-out duration-250 flex flex-col gap-6 p-6 bg-gray-F7F7F5 border-x border-gray-E6E6E3",
            showClasses
          )}
        >
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <TextareaField
                name="message"
                label="Your Message"
                control={form.control}
                inputClassName="h-48"
                required
              />
              <div className="flex items-center gap-6 justify-between">
                <p className="text-gray-999">
                  This candidate will receive an email with you CC’d
                </p>
                <div className="flex gap-4">
                  <Button
                    variant="ghost"
                    onClick={() => form.setValue("message", "")}
                    disabled={form.formState.isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={form.formState.isSubmitting}>
                    Send
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </div>
      )}
    </>
  )
}

const SEND_CANDIDATE_EMAIL_MUTATION = gql`
  mutation SendCandidateEmail($input: SendCandidateEmailInput!) {
    sendCandidateEmail(input: $input) {
      sent
    }
  }
`
