import { Divider } from "./Divider"

export const PageHeader = ({
  title,
  subhead,
  children,
  left,
}: {
  title: string
  subhead?: string
  children?: React.ReactNode
  left?: React.ReactNode
}) => (
  <>
    <div className="flex flex-row gap-4 items-center">
      {left}
      <div className="flex-grow">
        <h1 className="font-medium text-gray-333 text-2xl">{title}</h1>
        {subhead && <h2 className="font-medium text-gray-999">{subhead}</h2>}
      </div>
      {children}
    </div>
    <Divider />
  </>
)
