import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import {
  CandidateTestPreview_QueryFragment,
  CandidateTestPreview_QuestionResponseFragment,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import { organizationTestQuestionsPath } from "~/common/paths"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Divider } from "~/ui/Divider"
import { OrganizationLogo } from "~/organizations/OrganizationLogo"
import { Button, LinkButton } from "~/shadcn/ui/button"
import { Link } from "react-router-dom"
import { Navigation } from "~/organizations/Navigation"
import ChevronLeftIcon from "~/images/icons/chevron-left.svg?react"
import { humanizeSeconds } from "~/common/dates"
import { Markdown } from "~/ui/Markdown"

export const OrganizationTestPreviewScreen = () => {
  const { testId } = useOrganizationTest()
  const organizationId = useOrganizationId()

  const { data, loading, refetch } = useQuery(CANDIDATE_TEST_PREVIEW_QUERY, {
    variables: { testId },
  })

  const previewTest = data?.candidateTestPreview

  return (
    <div className="flex flex-col gap-4 mb-10">
      <Navigation organizationId={organizationId} testId={testId} />
      <div className="flex flex-col">
        <div className="text-gray-333 text-base py-2">
          <Link
            className="flex flex-row items-center gap-4"
            to={organizationTestQuestionsPath({ organizationId, testId })}
          >
            <ChevronLeftIcon />
            <span className="text-gray-999">Edit Questions</span>
          </Link>
        </div>
      </div>
      <Divider />
      {loading && <LoadingIndicatorCentered />}
      {!loading && previewTest && (
        <CandidateTestPreviewComponent
          organizationId={organizationId}
          previewTest={previewTest}
          onRegenerate={refetch}
        />
      )}
    </div>
  )
}

const CandidateTestPreviewComponent = ({
  organizationId,
  previewTest,
  onRegenerate,
}: {
  organizationId: string
  previewTest: CandidateTestPreview_QueryFragment
  onRegenerate: () => void
}) => {
  const handleRegenerate = () => {
    onRegenerate()
  }
  return (
    <div className="flex flex-col">
      <div className="my-8 flex items-center">
        <OrganizationLogo organization={previewTest.test.organization} />
        <div className="text-2xl text-gray-333 font-semibold">
          {previewTest.test.name}
        </div>
        {previewTest.test.expectedTimeSeconds && (
          <div className="ml-auto">
            <div className="text-sm text-gray-999 text-right">
              Expected Time
            </div>
            <div className="text-right">
              {humanizeSeconds(previewTest.test.expectedTimeSeconds)}
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="text-sm text-gray-999">Introduction</div>
        <div className="text-gray-333">
          <Markdown>{previewTest.test.description}</Markdown>
        </div>
      </div>

      <Divider className="my-10" />

      <form>
        {previewTest.questionResponses.map((qr, idx) => (
          <CandidateTestPreviewQuestion
            key={qr.id}
            questionResponse={qr}
            index={idx}
            questionCount={previewTest.questionCount}
          />
        ))}
      </form>

      <div className="flex justify-between">
        <Button onClick={handleRegenerate}>Regenerate</Button>
        <LinkButton
          to={organizationTestQuestionsPath({
            organizationId,
            testId: previewTest.test.id,
          })}
          variant="ghost"
        >
          Edit Questions
        </LinkButton>
      </div>
    </div>
  )
}

const CandidateTestPreviewQuestion = ({
  questionResponse,
  index,
  questionCount,
}: {
  questionResponse: CandidateTestPreview_QuestionResponseFragment
  index: number
  questionCount: number
}) => {
  const QuestionTypeComponent =
    QUESTION_TYPE_COMPONENTS[
      questionResponse.question.questionGroup.questionType
    ]

  return (
    <>
      <div className="bg-gray-F7F7F5 border border-gray-E6E6E3 rounded p-6 mb-6">
        <div className="mb-6 text-gray-999 flex items-center">
          <div className="flex-1">
            Question {(index + 1).toString()} of {questionCount.toString()}
          </div>
        </div>
        <div className="font-weight font-semibold text-2xl text-gray-333">
          {questionResponse.question.questionCopy}
        </div>
      </div>

      {QuestionTypeComponent && (
        <QuestionTypeComponent questionResponse={questionResponse} />
      )}

      <Divider className="my-10" />
    </>
  )
}

const ChoiceList = ({
  questionResponse,
}: {
  questionResponse: CandidateTestPreview_QuestionResponseFragment
}) => {
  return (
    <ul className="list-disc list-inside">
      {questionResponse.question.questionChoices.map((choice) => (
        <li key={choice.id} className="p-3 text-gray-333">
          {choice.choiceCopy}
        </li>
      ))}
    </ul>
  )
}

const TextResponseForm = ({
  questionResponse,
}: {
  questionResponse: CandidateTestPreview_QuestionResponseFragment
}) => {
  return (
    <div>
      <div className="mb-4">This is a text entry response.</div>
    </div>
  )
}
const SingleChoiceResponseForm = ({
  questionResponse,
}: {
  questionResponse: CandidateTestPreview_QuestionResponseFragment
}) => {
  return (
    <div>
      <div className="mb-4">Select One Option</div>

      <ChoiceList questionResponse={questionResponse} />
    </div>
  )
}
const MultipleChoiceResponseForm = ({
  questionResponse,
}: {
  questionResponse: CandidateTestPreview_QuestionResponseFragment
}) => {
  return (
    <div>
      <div className="mb-4">Select All That Apply</div>

      <ChoiceList questionResponse={questionResponse} />
    </div>
  )
}
const VideoResponseForm = ({
  questionResponse,
}: {
  questionResponse: CandidateTestPreview_QuestionResponseFragment
}) => {
  return (
    <div>
      <div className="mb-4">
        This is a video and audio response. Please speak your answer and then
        click continue.
      </div>
    </div>
  )
}

const QUESTION_TYPE_COMPONENTS: Record<
  QuestionTypeEnum,
  React.FC<{ questionResponse: CandidateTestPreview_QuestionResponseFragment }>
> = {
  [QuestionTypeEnum.TextResponse]: TextResponseForm,
  [QuestionTypeEnum.SingleChoice]: SingleChoiceResponseForm,
  [QuestionTypeEnum.MultipleChoice]: MultipleChoiceResponseForm,
  [QuestionTypeEnum.VideoResponse]: VideoResponseForm,
}

gql(`
  fragment CandidateTestPreview_QuestionResponse on QuestionResponse {
    id
    question {
      id
      questionCopy
      questionChoices {
        id
        choiceCopy
      }
      questionGroup {
        id
        questionType
      }
    }
  }
`)

gql(`
  fragment CandidateTestPreview_Query on CandidateTestPreview {
    id
    state
    questionCount
    test {
      id
      name
      description
      expectedTimeSeconds
      organization {
        id
        logoThumbUrl
      }
    }
    user {
      id
      email
    }
    questionResponses {
      ...CandidateTestPreview_QuestionResponse
    }
  }
`)

const CANDIDATE_TEST_PREVIEW_QUERY = gql(`
  query CandidateTestPreview($testId: ID!) {
    candidateTestPreview(testId: $testId) {
      ...CandidateTestPreview_Query
    }
  }
`)
