import { useMemo, useState } from "react"
import {
  QuestionResponse_DetailsFragment,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import { QUESTION_TYPE_NAMES } from "./QuestionGroupForm/utils"
import { secondsToMMSS } from "~/common/secondsToMMSS"
import ReactPlayer from "react-player"
import { ManualScoreDrawer } from "./ManualScoreDialog"
import { Markdown } from "~/ui/Markdown"
import { Score } from "~/ui/Score"
import { Card, CardSegment, CardSegmentStacked } from "~/ui/Card"
import { Badge } from "~/shadcn/ui/badge"
import { Separator } from "~/shadcn/ui/separator"
import {
  ChevronDown,
  ChevronUp,
  ClipboardEdit,
  Wand2,
  Watch,
} from "lucide-react"
import { Button } from "~/shadcn/ui/button"
import { dateTimePlusSeconds, formatTimeWithSeconds } from "~/common/dates"
import { Tooltip, TooltipContent, TooltipProvider } from "~/shadcn/ui/tooltip"
import { TooltipTrigger } from "@radix-ui/react-tooltip"
import { ChoiceQuestionResponse } from "./ChoiceQuestionResponse"

const VIDEO_ASPECT_RATIO = 1280 / 720
const VIDEO_WIDTH = 500
const VIDEO_HEIGHT = (1 / VIDEO_ASPECT_RATIO) * VIDEO_WIDTH

export const QuestionResponseDetails = ({
  questionResponse,
  canEdit,
}: {
  questionResponse: QuestionResponse_DetailsFragment
  canEdit?: false
}) => {
  const [fullDetailsExpanded, setFullDetailsExpanded] = useState(false)
  const responseText = responseForQuestionType(questionResponse)

  const timelineRange = useMemo(() => {
    const timeTaken = questionResponse.timeTakenSeconds
      ? questionResponse.timeTakenSeconds
      : 0
    const timeLimit = questionResponse.questionGroup.timeLimitSeconds
      ? questionResponse.questionGroup.timeLimitSeconds
      : 0
    const expectedTime = questionResponse.questionGroup.expectedTimeSeconds
      ? questionResponse.questionGroup.expectedTimeSeconds
      : 0
    return Math.max(timeTaken, timeLimit, expectedTime) + 0.25
  }, [questionResponse])

  const timelineFillPercentage = useMemo(() => {
    if (!questionResponse.timeTakenSeconds) {
      return 0
    }

    return Math.round((questionResponse.timeTakenSeconds / timelineRange) * 100)
  }, [questionResponse, timelineRange])

  const expectedTimePercentage = useMemo(() => {
    if (!questionResponse.questionGroup.expectedTimeSeconds) {
      return 0
    }

    return Math.round(
      (questionResponse.questionGroup.expectedTimeSeconds / timelineRange) * 100
    )
  }, [questionResponse, timelineRange])

  const startedAt = (response: QuestionResponse_DetailsFragment) => {
    return (
      response.questionAttempts.length > 0 &&
      response.questionAttempts[0].startedAt
    )
  }

  const endedAt = (response: QuestionResponse_DetailsFragment) => {
    const startTime = startedAt(response)
    if (response.timeTakenSeconds && startTime) {
      return dateTimePlusSeconds(startTime, response.timeTakenSeconds)
    } else {
      return null
    }
  }

  const [showManualScore, setShowManualScore] = useState(false)

  return (
    <div className="flex flex-col gap-4 mb-6">
      <Card className="divide-y-0">
        <CardSegmentStacked
          label={
            QUESTION_TYPE_NAMES[questionResponse.questionGroup.questionType]
          }
        >
          {[
            QuestionTypeEnum.TextResponse,
            QuestionTypeEnum.VideoResponse,
          ].includes(questionResponse.questionGroup.questionType) && (
            <>{responseText && <Markdown>{responseText}</Markdown>}</>
          )}
          {[
            QuestionTypeEnum.SingleChoice,
            QuestionTypeEnum.MultipleChoice,
          ].includes(questionResponse.questionGroup.questionType) && (
            <ChoiceQuestionResponse
              questionResponse={questionResponse}
              className="mt-4"
            />
          )}
        </CardSegmentStacked>

        {questionResponse.questionAttempts.map((attempt, index) => (
          <CardSegmentStacked key={attempt.id} label={`Recording ${index + 1}`}>
            <div>
              {attempt.playbackUrl ? (
                <ReactPlayer
                  url={attempt.playbackUrl}
                  controls
                  width={VIDEO_WIDTH}
                  height={VIDEO_HEIGHT}
                />
              ) : (
                `Transcoding state: ${attempt.transcodingState}`
              )}
            </div>
            <Markdown>
              {attempt.transcript
                ? attempt.transcript
                : "Transcript not available"}
            </Markdown>
          </CardSegmentStacked>
        ))}

        <div className="p-4 flex gap-8 items-center">
          <div className="text-gray-999 text-sm font-medium">Question Tags</div>
          <div className="text-gray-333">
            <div className="flex gap-1">
              {questionResponse.questionGroup.tags.map((tag) => (
                <Badge key={tag.id}>{tag.name}</Badge>
              ))}
            </div>
          </div>
        </div>

        <Separator />

        <div className="grid grid-cols-3 divide-x">
          <div className="flex gap-2 p-4 items-center">
            <div className="p-3 rounded-full bg-gray-F7F7F5">
              <Watch className="w-5 h-5" />
            </div>
            <div>
              <div className="text-gray-999 text-xs">Question Time</div>
              <div className="text-gray-333 text-sm mt-1">
                {secondsToMMSS(questionResponse.timeTakenSeconds)}
              </div>
            </div>
          </div>

          <div className="flex gap-2 p-4 items-center">
            <div className="p-3 rounded-full bg-gray-F7F7F5">
              <ClipboardEdit className="w-5 h-5" />
            </div>
            <div className="flex justify-between items-center flex-grow">
              <div>
                <div className="text-gray-999 text-xs">Manual Score</div>
                <div className="text-gray-333 text-sm mt-1">
                  {questionResponse.manualScore || 0}%
                </div>
              </div>
              <div className="flex justify-end">
                {canEdit && (
                  <button
                    className="text-primary"
                    onClick={() => setShowManualScore(!showManualScore)}
                  >
                    {showManualScore ? "Hide" : "Edit"}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-2 p-4 items-center">
            <div className="p-3 rounded-full bg-gray-F7F7F5">
              <Wand2 className="w-5 h-5" />
            </div>
            <div>
              <div className="text-gray-999 text-xs">AI Score</div>
              <div className="text-gray-333 text-sm">
                {questionResponse.aiScore !== null &&
                questionResponse.aiScore !== undefined ? (
                  <Score value={questionResponse.aiScore} />
                ) : (
                  <span className="text-gray-999">Not scored</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <Separator />

        {canEdit && (
          <ManualScoreDrawer
            questionResponse={questionResponse}
            show={showManualScore}
          />
        )}

        <Separator />

        <div className="flex gap-4 p-4 items-center">
          <div>00:00</div>
          <div className="flex-grow">
            <div className="w-full bg-gray-EFEFED rounded-full relative h-2">
              <div
                className={`bg-primary rounded-full h-2 absolute left-0 top-0`}
                style={{ width: `${timelineFillPercentage}%` }}
              ></div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className="w-6 h-6 flex justify-center items-center absolute -top-2 -translate-x-3 cursor-help"
                      style={{ left: `${timelineFillPercentage}%` }}
                    >
                      <div className="w-px h-6 bg-black"></div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="text-gray-999 text-sm">Actual Time</div>
                    <div>
                      {secondsToMMSS(questionResponse.timeTakenSeconds || 0)}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              {!!questionResponse.questionGroup.expectedTimeSeconds && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div
                        className="w-6 h-6 flex justify-center items-center absolute -top-2 -translate-x-3 cursor-help"
                        style={{ left: `${expectedTimePercentage}%` }}
                      >
                        <div className="w-px h-6 bg-black"></div>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="text-gray-999 text-sm">Expected Time</div>
                      <div>
                        {secondsToMMSS(
                          questionResponse.questionGroup.expectedTimeSeconds
                        )}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          </div>
          <div>{secondsToMMSS(timelineRange)}</div>
        </div>
      </Card>

      <Card variant="panel">
        <div className="p-4 flex gap-4 items-center">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setFullDetailsExpanded(!fullDetailsExpanded)}
          >
            {fullDetailsExpanded ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
          </Button>
          <div>View Full Details</div>
        </div>
        {fullDetailsExpanded && (
          <>
            <Separator />
            <CardSegment label="Question Difficulty">
              {questionResponse.questionGroup.difficulty}
            </CardSegment>
            <CardSegment label="Question Frequency">
              {questionResponse.question.frequencyPercentage || 100}%
            </CardSegment>
            <CardSegment label="Start Timestamp">
              {!!startedAt(questionResponse)
                ? formatTimeWithSeconds(startedAt(questionResponse))
                : "-"}
            </CardSegment>
            <CardSegment label="End Timestamp">
              {!!endedAt(questionResponse)
                ? formatTimeWithSeconds(endedAt(questionResponse))
                : "-"}
            </CardSegment>
            <CardSegment label="Total Response Time">
              {secondsToMMSS(questionResponse.timeTakenSeconds)}
            </CardSegment>
            <CardSegment label="Good Answer Example">
              {questionResponse.question.exampleGoodAnswer || "-"}
            </CardSegment>
            <CardSegment label="Bad Answer Example">
              {questionResponse.question.exampleBadAnswer || "-"}
            </CardSegment>
          </>
        )}
      </Card>
    </div>
  )
}

const responseForQuestionType = (
  questionResponse: QuestionResponse_DetailsFragment
) => {
  if (
    questionResponse.questionGroup.questionType ===
    QuestionTypeEnum.TextResponse
  ) {
    return questionResponse.textResponse
  } else if (
    questionResponse.questionGroup.questionType ===
    QuestionTypeEnum.VideoResponse
  ) {
    return questionResponse.questionAttempts[
      questionResponse.questionAttempts.length - 1
    ]?.transcript
  } else {
    return questionResponse.responseChoices.map((c) => c.choiceCopy).join(", ")
  }
}
