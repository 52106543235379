import { gql } from "~/__generated__"

gql(`
  fragment CandidateTest_CandidateTestResponses on CandidateTest {
    id
    startedAt
    completedAt
    timeTakenSeconds

    user {
      id
      name
      email
      phone
    }
    test {
      id
      name
      updatedAt

      organization {
        id
        name
      }
    }
  }
`)

gql(`
  fragment QuestionResponse_CandidateTestResponses on QuestionResponse {
    id
    state
    textResponse
    timeTakenSeconds
    manualScore
    aiScore
    questionAttempts {
      id
      transcript
      timeTakenSeconds
      transcodingState
      playbackUrl
      startedAt
    }
    responseChoices {
      id
      choiceCopy
      isCorrect
    }
    questionGroup {
      id
      name
      questionType
      frequencyPercentage
      timeLimitSeconds
      expectedTimeSeconds
      difficulty
      tags {
        id
        name
      }
    }
    question {
      id
      questionCopy
      exampleBadAnswer
      exampleGoodAnswer
      scoringPrompt
      frequencyPercentage
      questionChoices {
        id
        choiceCopy
        isCorrect
      }
    }
  }
`)

gql(`
  fragment QuestionResponse_Details on QuestionResponse {
    ...QuestionResponse_CandidateTestResponses

    nextQuestionResponseId
    previousQuestionResponseId
    questionNumber
    manualScoreNote
    candidateTest {
      id
      questionCount
      ...CandidateTest_CandidateTestResponses
    }
  }
`)

export const QUESTION_RESPONSE_QUERY_DOCUMENT = gql(`
  query QuestionResponseDetails($questionResponseId: ID!) {
    questionResponse(questionResponseId: $questionResponseId) {
      ...QuestionResponse_Details
    }
  }
`)

export const SHARE_QUESTION_RESPONSE_QUERY_DOCUMENT = gql(`
  query ShareQuestionResponseDetails($questionResponseId: ID!, $shareTokenId: ID) {
    questionResponse(questionResponseId: $questionResponseId, shareTokenId: $shareTokenId) {
      ...QuestionResponse_Details
    }
  }
`)

export const ADMIN_QUESTION_RESPONSE_QUERY_DOCUMENT = gql(`
  query AdminQuestionResponse($questionResponseId: ID!) {
    questionResponse(questionResponseId: $questionResponseId) {
      ...QuestionResponse_Details
    }
  }
`)

export const CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT = gql(`
  query CandidateTestQuestionResponses($candidateTestId: ID!) {
    candidateTest(candidateTestId: $candidateTestId) {
      ...CandidateTest_CandidateTestResponses
    }

    candidateTestQuestionResponses(candidateTestId: $candidateTestId) {
      nodes {
        ...QuestionResponse_CandidateTestResponses
      }
    }
  }
`)

export const SHARE_CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT = gql(`
  query ShareCandidateTestQuestionResponses($shareTokenId: ID!) {
    candidateTest(shareTokenId: $shareTokenId) {
      ...CandidateTest_CandidateTestResponses
    }

    candidateTestQuestionResponses(shareTokenId: $shareTokenId) {
      nodes {
        ...QuestionResponse_CandidateTestResponses
      }
    }

  }
`)
