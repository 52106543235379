import { Outlet, ScrollRestoration } from "react-router-dom"
import { Footer } from "~/ui/Footer"
import { HeaderNavigation } from "~/ui/HeaderNavigation"

export const ShareLayout = () => {
  return (
    <div id="top" className="flex-1 flex flex-col">
      <HeaderNavigation currentUser={null}>
        <div />
      </HeaderNavigation>

      <main className="flex-1 mx-auto max-w-[1024px] w-full px-6">
        <Outlet />
      </main>

      <div className="mx-auto max-w-[1024px] w-full">
        <Footer />
      </div>
      <ScrollRestoration />
    </div>
  )
}
