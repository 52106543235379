import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import {
  organizationQuestionResponsePath,
  organizationTestResponsePath,
} from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { QuestionResponseDetails } from "~/questions/QuestionResponseDetails"
import { Navigation } from "~/organizations/Navigation"
import { QuestionNavigator } from "~/questions/QuestionNavigator"
import { QUESTION_RESPONSE_QUERY_DOCUMENT } from "~/tests/candidateTestQueries"
import { ShareButton } from "~/tests/ShareUI"

export const OrganizationTestQuestionResponseScreen = () => {
  const { test } = useOrganizationTest()
  const { questionResponseId } = useParams()
  invariant(questionResponseId)

  const { loading, data, error } = useQuery(QUESTION_RESPONSE_QUERY_DOCUMENT, {
    variables: { questionResponseId },
  })

  const organizationId = useOrganizationId()
  const testId = test?.id || ""

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data)
    return <Error message="Unable to load question details." />

  return (
    <CandidateTestLayout
      test={test}
      candidateTest={data?.questionResponse.candidateTest}
      questionResponse={data.questionResponse}
      backLabel="Responses"
      backPath={organizationTestResponsePath({
        organizationId,
        testId,
        candidateTestId: data?.questionResponse.candidateTest.id || "",
      })}
      rightModule={
        <div className="flex items-center gap-4">
          <QuestionNavigator
            questionResponse={data.questionResponse}
            viewPath={(questionResponseId) =>
              organizationQuestionResponsePath({
                organizationId,
                testId,
                questionResponseId,
              })
            }
          />
          <ShareButton
            candidateTestId={data.questionResponse.candidateTest.id}
            questionResponseId={questionResponseId}
          />
        </div>
      }
      navigation={
        <Navigation organizationId={organizationId} testId={testId} />
      }
    >
      <QuestionResponseDetails questionResponse={data.questionResponse} />
      <QuestionNavigator
        questionResponse={data.questionResponse}
        viewPath={(questionResponseId) =>
          organizationQuestionResponsePath({
            organizationId,
            testId,
            questionResponseId,
          })
        }
      />
    </CandidateTestLayout>
  )
}
