import { useRef, useState, useEffect } from "react"
import {
  TestPublishStatusEnum,
  Test_OrgTestLayoutFragment,
} from "~/__generated__/graphql"
import { Button } from "~/shadcn/ui/button"
import { Label } from "~/shadcn/ui/label"
import { RadioGroup, RadioGroupItem } from "~/shadcn/ui/radio-group"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { gql } from "~/__generated__"
import { TestAccessEnum } from "~/__generated__/graphql"
import LinkIcon from "~/images/icons/link.svg?react"
import { PromoteCheckbox } from "~/tests/PromoteCheckbox"
import { AccessPanel } from "~/tests/AccessPanel"
import { AccessPanelInvite } from "~/tests/AccessPanelInvite"
import { candidateTestPath, fullUrl } from "~/common/paths"
import { PublishTestButton } from "~/tests/PublishStatusUI"

const RadioGroupOptionItem = ({
  id,
  label,
  description,
  value,
  children,
}: {
  id: string
  label: string
  description: string
  value: string
  children?: React.ReactNode
}) => {
  return (
    <div className="py-4 text-gray-333 flex flex-row gap-2">
      <RadioGroupItem value={value} id={id} className="flex-shrink-0" />
      <div className="flex flex-col gap-2">
        <Label htmlFor={id}>
          <span className="text-base text-gray-333 font-medium">{label}</span>
          <p className="text-sm text-gray-999">{description}</p>
        </Label>
        {children}
      </div>
    </div>
  )
}

const AccessPanelShare = ({
  title,
  subTitle,
  display,
  children,
}: {
  title: string
  subTitle: string
  display: boolean
  children: React.ReactNode
}) => {
  if (!display) {
    return null
  } else {
    return (
      <AccessPanel className="p-6 gap-6">
        <h3 className="text-base font-medium text-gray-333">{title}</h3>
        <span className="text-base font-medium text-gray-999">{subTitle}</span>
        <div className="flex flex-col gap-8">{children}</div>
      </AccessPanel>
    )
  }
}

const CopyButton = ({
  inputRef,
  disabled,
  onClick,
}: {
  inputRef: React.RefObject<HTMLInputElement>
  disabled: boolean
  onClick?: (success: boolean) => void
}) => {
  const handleCopyLink = () => {
    // Copy link to clipboard
    if (navigator.clipboard !== undefined && inputRef.current !== null) {
      navigator.clipboard.writeText(inputRef.current.value).then(() => {
        toast.success("Link copied to clipboard.")
        onClick?.(true)
      })
    } else {
      toast.error("Failed to copy link")
      onClick?.(false)
    }
  }
  return (
    <div>
      <Button
        type="button"
        variant="default"
        onClick={handleCopyLink}
        disabled={disabled}
      >
        <LinkIcon className="w-4 h-4 mr-2" />
        Copy Link
      </Button>
    </div>
  )
}

const CopyWarning = ({ display }: { display: boolean }) => {
  return (
    display && (
      <p className="text-base font-normal text-red-500 mt-2">
        Make sure to publish your test prior to sharing otherwise it will not be
        visible.
      </p>
    )
  )
}

export const AccessForm = ({ test }: { test: Test_OrgTestLayoutFragment }) => {
  const [testUpdate, { loading }] = useSafeMutation(TEST_UPDATE_ACCESS_MUTATION)
  const [displayCopyWarning, setDisplayCopyWarning] = useState(false)

  const copyButtonClicked = (success: boolean) => {
    if (success && test.publishStatus !== TestPublishStatusEnum.Published) {
      setDisplayCopyWarning(true)
    }
  }

  useEffect(() => {
    if (displayCopyWarning) {
      setTimeout(() => {
        setDisplayCopyWarning(false)
      }, 3500)
    }
  }, [displayCopyWarning, setDisplayCopyWarning])

  const handleValueChange = async (value: string) => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          testId: test.id,
          name: test.name as string,
          access: value as TestAccessEnum,
        },
      },
    })

    if (errors) {
      toast.error("Error updating test.")
      console.log(errors)
    } else if (data?.testUpdate?.test) {
      toast.success("Test access updated.")
    } else {
      toast.error("Error updating test.")
    }
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const buildLink = (testId: string) => {
    return fullUrl(candidateTestPath({ testId }))
  }

  return (
    <div className="flex flex-col gap-6">
      {test.publishStatus !== TestPublishStatusEnum.Published && (
        <div className="flex gap-4 justify-between p-6 rounded-lg bg-gray-F7F7F5 items-center">
          <div className="flex flex-col gap-2">
            <h2 className="text-gray-333 font-medium">
              Would you like to publish this test?
            </h2>
            <p className="text-gray-999 text-sm font-medium">
              Your test is unpublished, make sure to publish it before sending
              it to others.
            </p>
          </div>
          <div className="">
            <PublishTestButton test={test} />
          </div>
        </div>
      )}
      <div className="flex flex-row gap-6 items-start">
        <div className="flex flex-col max-w-[33%] border border-gray-E6E6E3 rounded-lg divide-y divide-gray-E6E6E3">
          <h3 className="text-base font-medium text-gray-333 p-6">
            Permissions
          </h3>
          <div className="px-6">
            <RadioGroup
              defaultValue={test.access}
              onValueChange={handleValueChange}
              disabled={loading}
            >
              <div className="flex flex-col divide-y divide-gray-E6E6E3">
                <RadioGroupOptionItem
                  id="public"
                  label="Public"
                  description="Public tests are open to all, and can be promoted on our website."
                  value="PUBLIC"
                ></RadioGroupOptionItem>
                <RadioGroupOptionItem
                  id="share-link"
                  label="Share Link"
                  description="Anyone with the share link has access to this test."
                  value="PRIVATE_URL"
                >
                  <CopyButton
                    inputRef={inputRef}
                    disabled={test.access !== TestAccessEnum.PrivateUrl}
                    onClick={copyButtonClicked}
                  />
                </RadioGroupOptionItem>
                <RadioGroupOptionItem
                  id="invite-only"
                  label="Invite Only"
                  description="Users must be invited to take or view this test."
                  value="EMAIL_INVITATION"
                />
              </div>
            </RadioGroup>
          </div>
        </div>
        <AccessPanelShare
          title="Public"
          subTitle="Copy the public link below to share the test."
          display={test.access === TestAccessEnum.Public}
        >
          <div>
            <label className="text-base font-medium text-gray-999">
              Public Link
              <input
                ref={inputRef}
                type="text"
                value={buildLink(test.id)}
                readOnly
                className="mt-2 w-full border border-gray-E6E6E3 rounded-lg p-4"
              />
            </label>
            <CopyWarning display={displayCopyWarning} />
          </div>

          <PromoteCheckbox test={test} />

          <CopyButton
            inputRef={inputRef}
            disabled={false}
            onClick={copyButtonClicked}
          />
        </AccessPanelShare>
        <AccessPanelShare
          title="Share Link"
          subTitle="Anyone with the share link has access to this test."
          display={test.access === TestAccessEnum.PrivateUrl}
        >
          <div>
            <label className="text-base font-medium text-gray-999">
              Share Link
              <input
                ref={inputRef}
                type="text"
                value={buildLink(test.id)}
                readOnly
                className="mt-2 w-full border border-gray-E6E6E3 rounded-lg p-4"
              />
              <CopyWarning display={displayCopyWarning} />
            </label>
          </div>

          <CopyButton
            inputRef={inputRef}
            disabled={false}
            onClick={copyButtonClicked}
          />
        </AccessPanelShare>
        <AccessPanelInvite
          test={test}
          display={test.access === TestAccessEnum.EmailInvitation}
        />
      </div>
    </div>
  )
}

const TEST_UPDATE_ACCESS_MUTATION = gql(`
  mutation TestAccessUpdate($input: TestUpdateInput!) {
    testUpdate(input: $input) {
      test {
        id
        access
      }
    }
  }
`)
