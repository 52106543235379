import { Upload, Pencil } from "lucide-react"
import { useState, useCallback } from "react"
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogClose,
  DialogHeader,
  DialogFooter,
  DialogPortal,
} from "~/shadcn/ui/dialog"
import { Button } from "~/shadcn/ui/button"
import { FileWithPath, useDropzone } from "react-dropzone"
import toast from "react-hot-toast"
import { cn } from "~/common/shadcn-utils"
import { directImageUpload } from "~/common/directImageUpload"
import { useSafeMutation } from "~/common/useSafeMutation"
import { LoadingIndicator } from "~/components/LoadingIndicator"
import { gql } from "~/__generated__"

export const OrganizationLogoUpload = ({
  organizationId,
  logoThumbUrl,
}: {
  organizationId: string
  logoThumbUrl?: string | null
}) => {
  const [open, setOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [runOrganizationUpdate] = useSafeMutation(
    ORGANIZATION_UPDATE_LOGO_MUTATION
  )

  const onFileAdd = useCallback(
    async (file: FileWithPath) => {
      setIsSaving(true)

      try {
        const { signedId } = await directImageUpload(file)

        const { errors } = await runOrganizationUpdate({
          variables: {
            input: {
              organizationId,
              logoSignedId: signedId,
            },
          },
        })

        if (errors) {
          toast.error("Failed to save logo")
        }
      } catch (e) {
        toast.error("Error uploading logo")
      }

      setOpen(false)
      setIsSaving(false)
    },
    [runOrganizationUpdate, organizationId]
  )

  const dropzoneBag = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 1000000,
    onDropRejected: (_fileRejections, _event) => {
      toast.error("Only files under 1mb are accepted.")
    },
    onDrop: (files) => {
      files.forEach((file) => onFileAdd(file))
    },
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {logoThumbUrl ? (
          <div className="relative group h-[120px] w-[120px]">
            <img
              className="rounded"
              src={logoThumbUrl}
              alt=""
              width="120"
              height="120"
            />
            <div className="absolute w-[120px] h-[120px] top-0 left-0 z-10 flex items-center justify-center invisible group-hover:visible">
              <Button variant="overImage" size="xs" className="gap-2">
                <Pencil size={12} />
                Edit Image
              </Button>
            </div>
          </div>
        ) : (
          <button className="flex flex-col gap-2 h-[120px] w-[120px] border border-gray-E6E6E3 bg-gray-F7F7F5 rounded justify-center text-primary">
            <div className="flex justify-center w-full">
              <Upload size={16} />
            </div>
            <span className="text-sm text-gray-666 w-full">Upload Image</span>
          </button>
        )}
      </DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <DialogHeader className="text-base font-semibold text-gray-333">
            Upload a logo
          </DialogHeader>
          {isSaving ? (
            <LoadingIndicator />
          ) : (
            <div
              {...dropzoneBag.getRootProps()}
              className={cn(
                "px-6 py-6 border-2 border-dashed border-gray-400 mb-8 cursor-pointer",
                {
                  "ring-2": dropzoneBag.isDragActive,
                }
              )}
            >
              <input {...dropzoneBag.getInputProps()} />
              Drag image files or click here to upload
            </div>
          )}
          <DialogFooter className="sm:justify-start">
            <DialogClose asChild>
              <Button type="button" variant="ghost">
                Close & Cancel
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

const ORGANIZATION_UPDATE_LOGO_MUTATION = gql(`
  mutation OrganizationUpdate($input: OrganizationUpdateInput!) {
    organizationUpdate(input: $input) {
      organization {
        id
        logoThumbUrl
      }
    }
  }
`)
