import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import {
  organizationQuestionResponsePath,
  organizationTestResponsesPath,
} from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { TestQuestionResponsesList } from "~/tests/TestQuestionResponsesList"
import { Navigation } from "~/organizations/Navigation"
import { CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT } from "~/tests/candidateTestQueries"
import { ShareButton } from "~/tests/ShareUI"
import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import { Mail } from "lucide-react"
import { EmailCandidateForm } from "~/organizations/EmailCandidateForm"

export const OrganizationTestResponseScreen = () => {
  const { test } = useOrganizationTest()
  const { candidateTestId } = useParams()
  invariant(candidateTestId)

  const { loading, data, error } = useQuery(
    CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT,
    {
      variables: { candidateTestId },
    }
  )

  const organizationId = useOrganizationId()
  const testId = test?.id || ""

  const [showEmailForm, setShowEmailForm] = useState(false)

  return (
    <CandidateTestLayout
      test={test}
      candidateTest={data?.candidateTest}
      backLabel="Responses"
      backPath={organizationTestResponsesPath({ organizationId, testId })}
      navigation={
        <Navigation organizationId={organizationId} testId={testId} />
      }
      rightProfile={
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowEmailForm(!showEmailForm)}
            className="gap-2 text-gray-333"
          >
            <Mail size={16} />
            Message Candidate
          </Button>
          {data && <ShareButton candidateTestId={data.candidateTest.id} />}
        </div>
      }
      underProfile={
        <EmailCandidateForm
          candidateTestId={candidateTestId}
          show={showEmailForm}
          onShowChange={setShowEmailForm}
        />
      }
    >
      {error ? <Error message="Error loading responses." /> : null}
      {loading ? (
        <LoadingIndicatorCentered />
      ) : data && data.candidateTestQuestionResponses.nodes.length > 0 ? (
        <TestQuestionResponsesList
          data={data.candidateTestQuestionResponses.nodes}
          viewPath={(response) =>
            organizationQuestionResponsePath({
              organizationId,
              testId,
              questionResponseId: response.id,
            })
          }
          onlyAnswered
        />
      ) : (
        <EmptyData>No responses yet.</EmptyData>
      )}
    </CandidateTestLayout>
  )
}
